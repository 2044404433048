/* General Reset and Body Styling */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

h1,
h2 {
  font-family: regular;
}

h3 {
  font-family: bold;
}
p {
  font-family: text;
  font-size: 1rem;
  margin-bottom: 1rem;
}
.link {
  color: #8c8c73;
}
a {
  text-decoration: none;
}
body,
html,
#root,
.App {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  background-color: #ddddd5;
}
/* Header Styling */
.header,
.headerimg {
  width: 100%;
  position: relative;
  /* Ensure padding/margin don't affect width */
}
/* Header Image Styling */
.headerimg {
  height: 50vh; /* Adjust height as needed */
  object-fit: cover;
  display: block; /* Remove inline-block whitespace issues */
}
/* Navigation Bar Styling */
.nav-bar,
.knoopnavy {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 6%;
  top: 70%;
  width: 100%;
  box-sizing: border-box; /* Ensure padding/margin don't affect width */
}
.nav-bar {
  position: absolute;
}
.knoopnavy {
  position: relative;
  z-index: 1;
}
.logo img,
.logosgv img {
  height: auto; /* Maintain aspect ratio */
  display: block; /* Ensure the image doesn’t have inline spacing issues */
  filter: url(#drop-shadow);
}
.logo img {
  max-width: 35vh;
}
.logosgv img {
  max-width: 17vh; /* Adjust the max-width as needed */
}
/* Logo Styling */
.logo,
.logosgv {
  position: absolute;
  top: 40%;
  transform: translate(-50%, -50%);
}
.logosgv {
  left: 90%;
}
.logo {
  left: 50%;
}
.logosgv img {
  max-width: 17vh; /* Adjust the max-width as needed */
}
/* Logo Styling */

/* Navigation List Styling */
.knoopnav {
  display: flex;
  list-style: none;
  padding: 15px;
  background-color: #8c8c73;
  border-radius: 15px;
  align-content: center;
  z-index: 2;
}
.nav {
  display: flex;
  list-style: none;
  padding: 15px;
  background-color: #8c8c73;
  border-radius: 15px;
  width: 90vw;
  align-content: center;
  justify-content: space-around;
  z-index: 2;
}
.nav li {
  display: flex;
  align-items: center;
}
.knoopnav li {
  display: flex;
  align-items: center;

  padding: 0 20px;
}
.meer {
  color: #262626;
}
.nav a,
.knoopnav a {
  text-decoration: none;
  color: #262626;
  font-size: 1.5em;
  font-family: text;
  transition: color 0.3s ease;
}
.nav a:hover,
.knoopnav a:hover,
.meer:hover {
  color: #ddddd5;
}
/* Responsive Styles */
.MasterGrid,
.LeidingGrid,
.verhuurGrid,
.eventlarge6,
.eventsmall3,
.eventGrid {
  width: 100%;
  box-sizing: border-box;
  padding-bottom: var(--gap);
  padding-top: var(--gap);
  padding-left: var(--gap);
  padding-right: var(--gap);
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  grid-auto-rows: var(--row-height);
  gap: var(--gap);
}
.MasterGrid{
  --gap: 6vw;
  --num-cols: 4;
  --row-height: 18vw;
}
.LeidingGrid {
  --gap: 6vw;
  --num-cols: 4;
  --row-height: 20vw;
}
.MasterGrid > div,
.LeidingGrid > div {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: relative;
}
.proxy {
  display: flex;
}

.proxy > div img {
  height: 60%;
  width: 100%;
  object-fit: contain;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  background-color: aliceblue;
}
.proxy > div h2 {
  display: flex;
  justify-content: center;
  font-size: 2vw;
}
.verhuurGrid{
  --gap: 2vw;
  --num-cols: 4;
  --row-height: 15.5vw;
}
.eventlarge6 {
  --gap: 3vw;
  --num-cols: 3;
  --row-height: 20.5vw;
  background-color: #8c8c73;
}
.eventsmall3 {
  --gap: 3vw;
  --num-cols: 3;
  --row-height: 16vw;
  background-color: #8c8c73;
}
.eventGrid {
  --gap: 3%;
  --num-cols: 3;
  --row-height: 10.5vw;
}

.centered {
  text-align: center;
  color: #262626;
  margin: 0;
}
.overflow {
  overflow: hidden;
}

.verhuurGrid > div,
.cards > div,
.cardst > div {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: relative;
  overflow: hidden;
}

.cards > div,
.cardst > div {
  background-color: aliceblue;
  border-radius: 10px;
}
.small3 {
  grid-column: span 3;
}
.large6 {
  grid-column: span 3;
  grid-row: span 2;
}

.cards > div img {
  height: 50%;
  width: 100%;
  object-fit: cover;
  position: relative;
  overflow: hidden;
  background-color: aliceblue;
}
.cardst > div img {
  height: 60%;
  width: 100%;
  object-fit: contain;
  position: relative;
  overflow: hidden;
  background-color: aliceblue;
}

.cardst > div h2 {
  display: flex;
  justify-content: center;
  font-size: 2vw;
}
.grid-col-4 {
  grid-column: span 4;
}
.grid-row-4 {
  grid-row: span 4;
}
.grid-col-2 {
  grid-column: span 2;
}
.grid-row-2 {
  grid-row: span 1;
}
.grid-col-3 {
  grid-column: span 3;
}
.grid-row-3 {
  grid-row: span 3;
}
.orange {
  color: orange;
  font-family: bold;
}
.input,
.menu-icon {
  display: none;
}
.imgTrooper {
  max-height: 40px;
  width: auto;
  object-fit: contain;
  position: relative;
  display: flex;
  align-items: center;
}
.textelement {
  margin: 6vw;
}
.textelement > p {
  margin: 1rem;
}
.knoop {
  margin-top: 0vw;
}
/* Responsive Styles */
/* Responsive Styles */
.footer1 {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}
.container1 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
}
ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  unicode-bidi: isolate;
}

.section1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}
.logo1 {
  width: 100px;
  margin-bottom: 10px;
}
.button1 {
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  margin: 5px;
}
.smallLogo1 {
  width: 80px;
  margin: 5px;
}
.loginButton1 {
  background-color: #fff;
  color: #333;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
}
.link1 {
  text-decoration: none;
  color: #ddddd5;
}
@media screen and (max-aspect-ratio: 13/9), (max-width: 800px) {
  .large6 {
    grid-column: span 4;
  }
  .small3 {
    grid-row: span 6;
    grid-column: span 4;
  }
  .eventsmall3 {
    --num-cols: 4;
    --row-height: 29.7vw;
  }
  .eventsmall3 > div {
    grid-row: span 2;
    grid-column: span 2;
  }
  .nav a:hover {
    color: #8c8c73;
  }
  .nav-bar {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0;
  }
  .menu-icon {
    position: fixed;
    top: 20px;
    left: 20px;
    display: flex;
    cursor: pointer;
    color: #8c8c73;
    width: 30px;
    height: 30px;
    font-size: 7vh;
    z-index: 1000; /* Ensure the menu icon is on top */
  }
  .nav {
    display: none; /* Hide the menu by default */
    position: fixed;
    top: 0;
    left: 0;
    width: 250px; /* Adjust width as needed */
    height: 100%;
    background-color: #ddddd5;
    padding-top: 180px; /* Space for the menu icon */
    padding-bottom: 30px;
    border-radius: 0px;
    transform: translateX(-100%); /* Hide off-screen by default */
  }
  #menu-toggle:checked + label + .nav {
    display: flex;
    flex-direction: column;
    transform: translateX(0); /* Slide in from the left */
  }
  #menu-toggle {
    display: none; /* Hide the checkbox */
  }
  .nav a {
    display: block;
    padding: 10px 20px;
  }
  .g1-card, .LeidingCard {
    grid-column: span 2;
    grid-row: span 2;
  }
  .logo {
    top: 50%;
  }
  .logosgv {
    top: 50%;
  }
  .knoopnav a {
    font-size: 1em;
  }

  .knoopnav li {
    padding: 0 7px;
  }
  .overflow {
    grid-row: span 2;
  }
}
@media screen and (max-width: 600px) {
  .card__container {
    grid-column: span 4;
    max-width: 100vw;
  }

  .swiper {
    grid-column: span 4;
  }
  .grid-row-3 {
    grid-row: span 4;
  }
  .overflow {
    grid-row: span 5;
    grid-column: span 4;
  }
  .eventlarge6 {
    --num-cols: 4;
    --row-height: 23.5vw;
  }

  .g1-card, .LeidingCard {
    grid-column: span 4;
    grid-row: span 4;
  }
  .eventlarge6 > div {
    grid-row: span 2;
    grid-column: span 2;
  }

  .container1 {
    flex-direction: column;
  }
}



.card_img_wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100;
  object-fit: cover;
}

.card_img {
  width: 100%;
  height: 100;
  transition: transform 0.3s ease;
  object-fit: cover;
}

.LeidingCard:hover .card_img {
  transform: scale(1.1);
}

.LeidingTitle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  text-align: center;
  transition: opacity 0.3s ease;
}

.LeidingInfo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Dark overlay */
  color: white;
  padding: 20px;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  text-align: center;
}

.LeidingCard:hover .LeidingTitle {
  opacity: 0;
}

.LeidingCard:hover .LeidingInfo {
  opacity: 1;
}
