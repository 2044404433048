@font-face {
    font-family: bold;
    src: url(./fonts/GeneralSans-Bold.ttf);
}
@font-face {
    font-family: regular;
    src: url(./fonts/GeneralSans-Regular.ttf);
}
@font-face {
    font-family: text;
    src: url(./fonts/CabinetGrotesk-Medium.ttf);
}
@font-face {
    font-family: ExtraBold;
    src: url(./fonts/CabinetGrotesk-Extrabold.ttf);
}
@font-face {
    font-family: slim;
    src: url(./fonts/GeneralSans-Light.ttf);
}