/*=============== GOOGLE FONTS ===============*/
@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@400;500;600&display=swap');

/*=============== VARIABLES CSS ===============*/
:root {
  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --first-color: hsl(38, 92%, 58%);
  --first-color-light: hsl(38, 100%, 78%);
  --first-color-alt: hsl(32, 75%, 50%);
  --second-color: #262626;
  --dark-color: #262626;
  --white-color: hsl(212, 4%, 95%);
  --body-color: hsl(212, 42%, 15%);
  --container-color: hsl(212, 42%, 20%);
  --swiper-theme-color: #262626;

  /*========== Font and typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
}

/*=============== BASE ===============*/

/*=============== CARD ===============*/
.swiper {
  height: 100%;
  width: 100%;
  padding: 6vh;
  grid-column: span 3;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.card__container {
  grid-column: span 3;
  height: 100%;
  width: 100%;
  background-color: #8c8c73;
}

.card__content {
  margin-inline: 1.75rem;
  border-radius: 1.25rem;
  overflow: hidden;
}

.card__article {
  width: 300px; /* Remove after adding swiper js */
  border-radius: 1.25rem;
  overflow: hidden;
}

.card__image {
  position: relative;
  background-color: var(--first-color-light);
  padding-top: 1.5rem;
  margin-bottom: -0.75rem;
}

.card__data {
  background-color: var(--container-color);
  padding: 1.5rem 2rem;
  border-radius: 1rem;
  text-align: center;
  position: relative;
  z-index: 10;
}

.card__img {
  height: 50%;
  width: 100%;
  object-fit: cover;
  position: relative;
  overflow: hidden;
  background-color: aliceblue;
}

.card__shadow {
  width: 200px;
  height: 200px;
  background-color: var(--first-color-alt);
  border-radius: 50%;
  position: absolute;
  top: 3.75rem;
  left: 0;
  right: 0;
  margin-inline: auto;
  filter: blur(45px);
}

.card__name {
  font-size: var(--h2-font-size);
  color: var(--second-color);
  margin-bottom: 0.75rem;
}

.card__description {
  font-weight: 500;
  margin-bottom: 1.75rem;
}

.card__button {
  display: inline-block;
  background-color: var(--first-color);
  padding: 0.75rem 1.5rem;
  border-radius: 0.25rem;
  color: #262626;
  font-weight: 600;
}

/* Swiper class */
.swiper-button-prev:after,
.swiper-button-next:after {
  content: '';
}

.swiper-button-prev,
.swiper-button-next {
  width: initial;
  height: initial;
  font-size: 3rem;
  color: #262626;
  display: none;
}
.swiper-slide {
  background-color: aliceblue;
  border-radius: 10px;
  overflow: hidden;
}
.swiper-button-prev {
  left: 0;
}

.swiper-button-next {
  right: 0;
}

.swiper-pagination-bullet {
  background-color: #262626;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: var(--second-color);
}

/* For large devices */

.card__container {
  max-width: 100vw;
}

.swiper-button-prev {
  left: -1rem;
}
.swiper-button-next {
  right: -1rem;
}
@media screen and (max-aspect-ratio: 13/9), (max-width: 800px) {
  .card__container {
    grid-column: span 3;
    grid-row: span 2;
    max-width: 100vw;
  }
}
@media screen and (max-width: 600px) {
  .card__container {
    grid-column: span 4;
    grid-row: span 5;
  }
  .swiper {
    padding: 5vh;
  }
  .card__img {
    height: 40%;
    width: 100%;
    object-fit: cover;
    position: relative;
    overflow: hidden;
    background-color: aliceblue;
  }
}
